<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="展示用户:" prop="client">
          <el-select v-model="table.params.client">
            <el-option label="需求端" :value="1"></el-option>
            <el-option label="师傅端" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动类型:" prop="type">
          <el-select v-model="table.params.type">
            <el-option v-for="item in typeOpt" :label="item.value" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动名称:" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:province="{row}">
        <span v-if="row.province == 0">全囯</span>
        <span v-else-if="row.area == 0">{{row.province_str}}{{`-${row.city_str}`}}</span>
        <span v-else>{{row.province_str}}{{`-${row.city_str}`}}{{`-${row.area_str}`}}</span>
      </template>
      <template v-slot:client="{row}">
        <span>{{toStr({1:'需求端',2:'师傅端',3:'全部'}, row.client)}}</span>
      </template>
      <template v-slot:start_time="{row}">
        <span>{{row.start_time}}至{{row.end_time}}</span>
      </template>
      <template v-slot:status="{row}">
        <span>{{toStr({ 1:'待进行', 2:'已结束', 3:'进行中', 4:'已到期'}, row.status)}}</span>
      </template>
      <template v-slot:value="{row}">
        <span v-if="row.unit == 1">
          {{row.value}} 元
        </span>
        <span v-else>
          {{row.value * 100}} %
        </span>
      </template>
      <template v-slot:action="{row}">
        <!-- 待进行、进行中，结束操作 -->
        <el-button v-if="row.status == 1 || row.status == 3 " type="danger" plain size="mini" @click="handleStatus(row)" >结束</el-button>
        <!-- 已結束并且剩余金额大于0，有生效操作 -->
        <el-button v-if="row.status == 2 && row.balance > 0" type="primary" plain size="mini" @click="handleStatus(row)">生效</el-button>
      </template>
    </VTable>

    <edit ref="edit" :typeOpt='typeOpt' :areaArr="areaArr" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'ActivityList',
  components: {
    VTable,
    Edit,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "province", label: "活动地区", hidden: false },
        { name: "client", label: "用户端", hidden: false },
        { name: "type_str", label: "活动类型", hidden: false },
        { name: "name", label: "活动名称", hidden: false },
        { name: "value", label: "折扣/优惠", hidden: false },
        { name: "once_amount", label: "单次优惠限额/元", hidden: false },
        { name: "issue_num", label: "发放次数", hidden: false },
        { name: "sum_amount", label: "投放金额", hidden: false },
        { name: "issue_amount", label: "已优惠金额", hidden: false },
        { name: "balance", label: "剩余金额", hidden: false },
        { name: "start_time", label: "活动期限", width:"200", hidden: false },
        { name: "create_time", label: "创建时间",width:"140", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          client: '',
          type: '',
          name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],
      typeOpt: []
    }
  },
  created() {
    this.getTable();
    this.getType();
    this.getArea();
  },
  methods: {
    getTable() {
      let _params = { ...this.table.params }
      this.$http.get('/admin/flex_activity/list', { params:_params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取活动类型
    getType() {
      this.$http.get('/admin/flex_activity/type',{ params: {} }).then(res => {
        if(res.code == 1) {
          this.typeOpt = res.data.list;
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
          let str = {
            label: "全囯",
            pid: 0,
            value:0,
            children:[
              {
                label: "全囯",
                pid: 0,
                value:0,
              }
            ]
          }
          this.areaArr.unshift(str)
        }
      })
    },
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.area_arr = '';
      this.getTable();
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 生效、结束
    handleStatus(row) {
      let str = row.status == 2 ? '生效' : '结束'
      this.$confirm(`请确认${str}操作？`, "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/admin/flex_activity/operation', { id: row.id }).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.getTable();
              } else {
                this.$message.error(res.data)
              }
            }).finally(() => { this.getTable() })
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
  }
}
</script>