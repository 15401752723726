<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='area' label='活动地区'>
        <el-cascader
          style="width:100%;"
          :popper-append-to-body="false"
          popper-class="areaCascaderWrap"
          v-model="form.data.area"
          :options="areaArr"
          :props="{ checkStrictly: true }">
        </el-cascader>
      </el-form-item>
      <el-form-item label="活动端口" prop="client">
        <el-select v-model="form.data.client" style="width:100%;">
          <el-option label="需求端" :value="1"></el-option>
          <el-option label="师傅端" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动类型" prop="type">
        <el-select v-model="form.data.type" style="width:100%;">
          <el-option v-for="item in typeOpt" :label="item.value" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop='name' label='活动名称'>
        <el-input clearable v-model="form.data.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop='value' label='折扣'>
        <el-row :gutter="10">
          <el-col :span="15">
            <el-input clearable v-model="form.data.value" placeholder="请输入"></el-input>
          </el-col>
          <el-col :span="8">
            <el-radio-group v-model="form.data.unit">
              <el-radio :label="2">%</el-radio>
              <el-radio :label="1">元</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop='once_amount' label='单次优惠限额' v-if="form.data.unit == 2 ">
        <el-input clearable v-model="form.data.once_amount" placeholder="请输入">
           <span slot="suffix">元</span>
        </el-input>
      </el-form-item>
      <el-form-item prop='sum_amount' label='投放金额'>
        <el-input clearable v-model="form.data.sum_amount" placeholder="请输入">
           <span slot="suffix">元</span>
        </el-input>
      </el-form-item>
      <el-form-item prop='time' label='活动期限'>
        <el-date-picker
          v-model="form.data.time"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
    
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'ActivityEdit',
    props:['areaArr','typeOpt'],
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            province: '',
            city: '',
            area: '',
            client: '',
            type: '',
            name: '',
            value: '',
            unit: '',
            once_amount: '',
            sum_amount: '',
            time:'',
            start_time: '',
            end_time: '',
          },
          rules: {
            area: [{ required: true, message:'必填项', trigger: 'change' }],
            client: [{ required: true, message:'必填项', trigger: 'change' }],
            type: [{ required: true, message:'必填项', trigger: 'change' }],
            name: [{ required: true, message:'必填项', trigger: 'change' }],
            value: [{ required: true, message:'必填项', trigger: 'change' }],
            unit: [{ required: true, message:'必填项', trigger: 'change' }],
            once_amount: [{ required: true, message:'必填项', trigger: 'change' }],
            sum_amount: [{ required: true, message:'必填项', trigger: 'change' }],
            time: [{ required: true, message:'必填项', trigger: 'change' }],
          }
        },
        activityOpt: [],
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true;
        this.form.data.area = [];
        this.$http.get('/admin/flex_banner/info', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
            if(res.data.province == 0) {
              this.form.data.area = [0]
            } else if(res.data.area == 0){
              this.form.data.area = [res.data.province,res.data.city]
            } else {
              this.form.data.area = [res.data.province,res.data.city,res.data.area]
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {...this.form.data}
            if(this.form.data.area[0] == 0) {
              _params.province = 0;
              _params.city = 0;
              _params.area = 0;
            } else {
              _params.province = this.form.data.area[0];
              _params.city = this.form.data.area[1];
              _params.area = this.form.data.area[2] || 0;
            }
            let _time = this.form.data.time
            _params.start_time = _time[0]
            _params.end_time = _time[1]
            _params.once_amount = this.form.data.value;
            delete _params.time
            console.log(_params)
            // return
            let apiUrl = !this.isChange ? '/admin/flex_activity/add' : '/admin/flex_activity/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        })
      }
    }
  }
</script>

<style>
.areaCascaderWrap .el-cascader-menu:nth-of-type(1) .el-radio {
  display: none !important;
}
</style>